import React, { useState, useEffect, useRef } from 'react';
import { IoSend } from 'react-icons/io5';

const ChatWindow = ({ user, messages, onAddMessage }) => {
  const [message, setMessage] = useState('');
  const chatEndRef = useRef(null);

  const handleSendMessage = () => {
    if (message.trim()) {
      onAddMessage(message);
      setMessage('');
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat window
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]); // Trigger scroll when messages change

  return (
    <div className="w-3/4 flex flex-col">
      {user ? (
        <div className="flex flex-col h-full">
          <div className="text-xl font-semibold p-4 border-b">{user.name}</div>
          <div className="flex-1 overflow-y-auto p-4">
            {messages.map((msg, index) => (
              <div key={index} className={`mb-2 ${msg.sender === 'You' ? 'text-right' : 'text-left'}`}>
                <div className={`inline-block px-4 py-2 rounded-lg ${msg.sender === 'You' ? 'bg-blue-500 text-white text-sm' : 'bg-gray-100 text-sm'}`}>
                  {msg.text}
                </div>
              </div>
            ))}
            {/* Empty div used to scroll to */}
            <div ref={chatEndRef} />
          </div>
          <div className="mt-auto flex  chatInputBox">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
              className="w-full p-2 border rounded-lg"
              placeholder="Type a message..."
            />
            <button
              onClick={handleSendMessage}
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg sendBtn"
            >
              <IoSend />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-500">Select a user to start chatting</p>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
