import { GrFormView } from "react-icons/gr";
import { MdEdit } from "react-icons/md";
import { Card, CardBody, CardFooter, IconButton, Button, Typography } from "@material-tailwind/react";
import React from 'react';

function VendorTable({ columns, data, loading, pageInfo, onNextPage, onPrevPage }) {
    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Typography variant="h6" color="blue-gray">
                    Loading...
                </Typography>
            </div>
        );
    }

    return (
        <Card className="h-full w-full overflow-scroll">
            <CardBody className="overflow-scroll px-0">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <th key={column} className="p-4 pt-10">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-bold leading-none"
                                    >
                                        {column}
                                    </Typography>
                                </th>
                            ))}
                            <th className="p-4 pt-10">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-bold leading-none"
                                >
                                    Actions
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((user, rowIndex) => (
                            <tr key={user.user_id || rowIndex}>
                                <td className="p-4">
                                    <Typography
                                        variant="small"
                                        className="font-normal text-gray-600"
                                    >
                                        {user.first_name}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography
                                        variant="small"
                                        className="font-normal text-gray-600"
                                    >
                                        {user.last_name}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography
                                        variant="small"
                                        className="font-normal text-gray-600"
                                    >
                                        {user.email}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography
                                        variant="small"
                                        className="font-normal text-gray-600"
                                    >
                                        {user.phone_number}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography
                                        variant="small"
                                        className="font-normal text-gray-600"
                                    >
                                        {user.type}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <div className="flex items-center gap-2">
                                        <IconButton variant="text" size="sm">
                                            <GrFormView className="h-4 w-4 text-gray-900" />
                                        </IconButton>
                                        <IconButton variant="text" size="sm">
                                            <MdEdit
                                                className="h-4 w-4 text-gray-900"
                                            />
                                        </IconButton>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CardBody>

            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                    Page {pageInfo.page} of {pageInfo.totalPages}
                </Typography>
                <div className="flex gap-2">
                    <Button variant="outlined" size="sm" onClick={onPrevPage} disabled={pageInfo.page === 1}>
                        Previous
                    </Button>
                    <Button variant="outlined" size="sm" onClick={onNextPage} disabled={pageInfo.page === pageInfo.totalPages}>
                        Next
                    </Button>
                </div>
            </CardFooter>
        </Card>
    );
}

export default VendorTable;