import { useEffect, useState } from "react";
import { FaGlobe, FaRegUserCircle, FaRobot, FaUserEdit } from "react-icons/fa";
import { _vendorCategories } from "Services/api";
import { _languageUpdate } from "Services/api";
import { _botCreation } from "Services/api";
import { IoIosGlobe, IoMdClose } from "react-icons/io";
import { _getPaymentGateways } from "Services/api";
import KnowledgeUpdateForm from "./components/KnowledgeUpdateForm";
import { _getLanguage } from "Services/api";
import { _update_bot } from "Services/api";
import { MdOutlinePayment } from "react-icons/md";
import { _getBotDetails } from "Services/api";

const Marketplace = () => {
  const [bgColor, setBgColor] = useState("#ccc");
  const [welcomeMessage, setWelcomeMessage] = useState("Hi! How can we help?");
  const [chatHeader, setChatHeader] = useState("Welcome to Plistbooking Chat");
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [organizationType, setOrganizationType] = useState("");
  const [categoryList, setCategoryList] = useState();
  const [isBotCreated, setIsBotCreated] = useState(false);
  const [botId, setBotId] = useState();
  const [scriptTag, setScriptTag] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const avatarOptions = [
    "https://avatar.iran.liara.run/public/12",
    "https://avatar.iran.liara.run/public/38",
    "https://avatar.iran.liara.run/public/48",
    "https://avatar.iran.liara.run/public/94",
    "https://avatar.iran.liara.run/public/55",
    "https://avatar.iran.liara.run/public/89",
  ];
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState("");
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeLanguages, setActiveLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(
    (activeLanguages && activeLanguages[0]?.code) || []
  );
  const [fetchedLanguages, setFetchedLanguages] = useState([]);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [languageError, setLanguageError] = useState("");
  const [avatarError, setAvatarError] = useState("");
  const [organizationTypeError, setOrganizationTypeError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [selectedColor, setSelectedColor] = useState(""); // New state to track selected color
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activeLanguages?.length > 0) {
      setDefaultLanguage(activeLanguages[0]?.code || "");
    }
  }, [activeLanguages]);

  // useEffect(() => {
  //   const storedIsCreateBot = localStorage.getItem("isCreateBot");
  //   if (storedIsCreateBot !== null) {
  //     setIsBotCreated(JSON.parse(storedIsCreateBot));
  //   }
  // }, []);

  useEffect(() => {
    if (isBotCreated) {
      const token = localStorage.getItem("VendorToken");
      const scriptString = `<script src="https://chatbotdev.plistbooking.app/script/script.js" bot-id="${botId}"></script>`;
      // _update_bot(botId, { script_tag: scriptString }, token);
      setScriptTag(scriptString);
    }
  }, [isBotCreated, botId]);

  // useEffect(() => {
  //   localStorage.setItem("isCreateBot", JSON.stringify(isBotCreated));
  // }, [isBotCreated]);

  const handleUpdateBot = async () => {
    setLoading(true);
    try {
        const vendorToken = localStorage.getItem("VendorToken");
        const persistedData = JSON.parse(localStorage.getItem("botData"));
        const updatedData = {};
        if (chatHeader !== persistedData.botname) {
            updatedData.botname = chatHeader;
        }
        if (bgColor !== persistedData.bot_color_code) {
            updatedData.bot_color_code = bgColor;
        }
        if (welcomeMessage !== persistedData.welcome_message) {
            updatedData.welcome_message = welcomeMessage;
        }

        // Check and update the Bot Organization Type (ensure correct comparison)
        if (String(organizationType) !== String(persistedData.bot_category_id)) {
            updatedData.bot_category_id = String(organizationType);
        }

        if (selectedAvatar !== persistedData.avatar) {
            updatedData.avatar = selectedAvatar;
        }
        if (selectedPaymentGateway !== persistedData.payment_gateway_id) {
            updatedData.payment_gateway_id = selectedPaymentGateway;
        }
        const currentSupportedLangs = activeLanguages.map((language) => language.code).join(",");
        if (currentSupportedLangs !== persistedData.supported_langs) {
            updatedData.supported_langs = currentSupportedLangs;
        }
        if (defaultLanguage?.toLowerCase() !== persistedData.default_lang) {
            updatedData.default_lang = defaultLanguage?.toLowerCase();
        }
        if ((scriptTag || "") !== persistedData.script_tag) {
            updatedData.script_tag = scriptTag || "";
        }

        console.log("Updated Data Payload:", updatedData); 
        const response = await _update_bot(botId, updatedData, vendorToken);

        if (response.status) {
            console.log("Bot updated successfully:", response);
            setErrorMessage("Bot updated successfully!");
        } else {
            setErrorMessage(response?.data?.message || "Failed to update bot");
        }
    } catch (error) {
        console.error("Error updating bot:", error);
        setErrorMessage(error?.response?.data?.message || "An error occurred while updating the bot");
    } finally {
        setLoading(false);
    }
};


  useEffect(() => {
    if (selectedPaymentGateway) {
      setPaymentMethodError("");
    }
  }, [selectedPaymentGateway]);

  const handleCategoryListAndPaymentGateways = async () => {
    try {
      const token = localStorage.getItem("VendorToken");
      const categories_response = await _vendorCategories();
      const payment_gateways_response = await _getPaymentGateways(token);
      console.log("payy", payment_gateways_response)
      if (categories_response.status) {
        setCategoryList(categories_response?.data?.categories);
      }

      if (payment_gateways_response.status) {
        setPaymentGateways(payment_gateways_response?.data?.payment_gateways);
        setSelectedPaymentGateway(
          payment_gateways_response?.data?.payment_gateways[0]?.payment_gateway_id
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCategoryListAndPaymentGateways();
  }, []);

  const fetchSupportedLanguages = async () => {
    try {
      const token = localStorage.getItem("VendorToken");
      const response = await _getLanguage({ accessToken: token });
      if (response.status) {
        setFetchedLanguages(response?.data);
      }
    } catch (error) {
      console.log("Error fetching languages:", error);
    }
  };

  const handleColorChange = (color) => {
    setBgColor(color);
    setSelectedColor(color);
  };

  const handleWelcomeMessageChange = (e) => {
    setWelcomeMessage(e.target.value);
  };

  const handleChatHeaderChange = (e) => {
    setChatHeader(e.target.value);
  };

  const handleAvatarSelection = (avatar) => {
    setSelectedAvatar(avatar);
    setAvatarError("");
  };

  const handleOrganizationTypeChange = (e) => {
    setOrganizationType(e.target.value);
    setOrganizationTypeError("");
  };

  const handleBotCreation = async () => {
    let hasError = false;
    setErrorMessage("");
    setAvatarError("");
    setLanguageError("");
    setOrganizationTypeError("");
    setPaymentMethodError("");

    // Check if an avatar is selected
    if (!selectedAvatar) {
      setAvatarError("Please select an avatar.");
      hasError = true;
    }
    if (!organizationType) {
      setOrganizationTypeError("Please select a bot organization type.");
      hasError = true;
    }
    if (activeLanguages?.length === 0) {
      setLanguageError("Please choose at least one language.");
      hasError = true;
    }
    if (!selectedPaymentGateway) {
      setPaymentMethodError("Please select a payment method.");
      hasError = true;
    }
    if (hasError) return;

    try {
      const vendorToken = localStorage.getItem("VendorToken");
      
      const body = {
        botname: chatHeader,
        bot_behavior_prompt: "hello",
        bot_color_code: bgColor,
        lang: "english",
        welcome_message: welcomeMessage,
        bot_category_id: String(organizationType),
        avatar: selectedAvatar,
        payment_gateway_id: selectedPaymentGateway,
        supported_langs: activeLanguages
          .map((language) => language.code)
          .join(","),
        default_lang: defaultLanguage.toLowerCase(),
        script_tag: scriptTag || "",
      };
      const response = await _botCreation({ body, accessToken: vendorToken });
      
      if (response.status) {
        setIsBotCreated(true);
        const newBotId = response?.data?.bot_id;
        setBotId(newBotId);
  
        // Now, set the script tag immediately after the bot is created
        const scriptString = `<script src="https://chatbotdev.plistbooking.app/script/script.js" bot-id="${newBotId}"></script>`;
        setScriptTag(scriptString);
        _update_bot(newBotId, { script_tag: scriptString }, vendorToken);
        
        // Store the bot data in localStorage
        localStorage.setItem("botData", JSON.stringify(body));
  
        // clear all fields
        // setChatHeader("Welcome to Plistbooking Chat");
        // setSelectedAvatar("https://cdn-icons-png.flaticon.com/128/6997/6997662.png");
        // setOrganizationType("");
        // setBgColor("#67c291");
        // setWelcomeMessage("Hi! How can we help?");
        // setActiveLanguages([]);
        // setDefaultLanguage("");
        // setSelectedPaymentGateway("");
        // setErrorMessage("");
      } else {
        setIsBotCreated(false);
        setErrorMessage(response?.data?.message);
      }
    } catch (error) {
      setIsBotCreated(false);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const handleCopyScript = () => {
    navigator.clipboard.writeText(scriptTag);
    alert("Script copied to clipboard!");
  };

  const openLanguageModal = () => {
    setIsModalOpen(true);
    if (fetchedLanguages.length === 0) {
      fetchSupportedLanguages();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLanguageClick = (selectedLanguage) => {
    // Check what activeLanguages looks like before modifying it
    console.log("Before:", activeLanguages);

    const currentActiveLanguages = activeLanguages || [];

    const isLanguageActive = currentActiveLanguages.some(
      (language) => language.code === selectedLanguage.code
    );

    if (isLanguageActive) {
      setActiveLanguages(
        currentActiveLanguages.filter(
          (language) => language.code !== selectedLanguage.code
        )
      );
    } else {
      console.log("Adding selectedLanguage:", selectedLanguage);
      setActiveLanguages([...currentActiveLanguages, selectedLanguage]);
    }

    console.log("After:", currentActiveLanguages);
    setLanguageError(""); // Clear language error when a language is selected
  };

  const handlehandleUpdateBotSupportLang = async () => {
    setLoading(true); // Show loader
    try {
      const vendorToken = localStorage.getItem("VendorToken");
      console.log("token", vendorToken);

      const activeLanguagesCode = activeLanguages?.map((lang) => lang.code);

      const body = {
        bot_id: "ca9763c7-c1a1-42f4-b919-1bf79f7944bd",
        supported_langs: activeLanguagesCode.join(","),
      };

      const response = await _languageUpdate({
        body,
        accessToken: vendorToken,
      });

      if (response.status) {
        console.log("language", response);
        setIsModalOpen(false); // Close the modal on success
      } else {
        setErrorMessage(response?.data?.message); // Set error message if response fails
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error?.response?.data?.message); // Set error message on exception
    } finally {
      setLoading(false); // Hide loader once the request is complete
    }
  };

  const handlePaymentGatewayChange = (event) => {
    setSelectedPaymentGateway(event.target.value);
    setPaymentMethodError(""); // Clear payment method error when a gateway is selected
  };

  const getBotDetails = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("VendorToken");
      const botDetails = await _getBotDetails({ accessToken: token });
      if (botDetails?.status) {
        setIsBotCreated(true)
        const selectedBot = botDetails?.data;
        localStorage.setItem("botData", JSON.stringify(botDetails?.data));
        console.log("bot details ", selectedBot);
        setChatHeader(selectedBot?.botname);
        setSelectedAvatar(selectedBot?.avatar);
        setOrganizationType(selectedBot?.bot_category);
        setBgColor(selectedBot?.bot_color_code);
        setWelcomeMessage(selectedBot?.welcome_message);
        setActiveLanguages(selectedBot?.supported_langs);
        setDefaultLanguage(selectedBot?.default_lang);
        setSelectedPaymentGateway(selectedBot?.payment_gateway);
        setScriptTag(selectedBot?.script_tag);
        setErrorMessage("");
        setBotId(selectedBot?.unique_id);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error?.response?.data?.message);
    } finally {
      setLoading(false); // Hide loader once the request is complete
    }
  };

  useEffect(() => {
    getBotDetails();
  }, []);

  return (
    <>
      <div className="">
        {/* <div className="tabsView">
          <button
            className={activeTab === "dashboard" ? "active" : ""}
            onClick={() => handleTabChange("dashboard")}
          >
            Bot Appearance
          </button>
          <button
            className={activeTab === "knowledge" ? "active" : ""}
            onClick={() => handleTabChange("knowledge")}
          >
            Bot Knowledge
          </button>
        </div> */}

        {console.log(errorMessage, "error console")}

        <div>
          {activeTab === "dashboard" && (
            <div>
              <div className="mx-auto flex justify-between">
                <div className="w-full">
                  {errorMessage && (
                    <div className="mt-2">
                      <p
                        className="text-sm text-red-500"
                        style={{ padding: "20px", paddingBottom: "0" }}
                      >
                        {errorMessage}
                      </p>
                    </div>
                  )}

                  <div className="mt-4">
                    <div className="rounded-md bg-white p-4 shadow">
                      <h1 className="text-xl text-[#666]">
                        <b className="align-center flex gap-2">
                          <FaRegUserCircle /> Identity
                        </b>
                      </h1>
                      <hr className="my-3" />
                      <div className="mb-4">
                        <label className="text-black-800 block font-bold">
                          BOT Name
                        </label>
                        <input
                          required
                          type="text"
                          className="mt-1 w-full rounded border p-2"
                          placeholder="Welcome to Plistbooking Chat"
                          value={chatHeader}
                          onChange={handleChatHeaderChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label className="text-black-800 block font-bold">
                          Welcome Message
                        </label>
                        <input
                          type="text"
                          className="mt-1 w-full rounded border p-2"
                          placeholder="Hi! How can we help?"
                          value={welcomeMessage}
                          onChange={handleWelcomeMessageChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label className="text-black-800 block font-bold">
                          Bot Organization Type
                        </label>
                        <select
                          className="mt-1 w-full rounded border p-2"
                          value={organizationType}
                          onChange={handleOrganizationTypeChange}
                          // disabled={!!scriptTag}
                        >
                          <option value="" disabled>
                            Select Organization Type
                          </option>
                          {categoryList?.map((type, index) => (
                            <option key={index} value={type?.category_id}>
                              {type?.category_name}
                            </option>
                          ))}
                        </select>
                        {organizationTypeError && (
                          <div className="mt-2">
                            <p className="text-sm text-red-500">
                              {organizationTypeError}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 rounded-md bg-white p-4 shadow">
                      <h1 className="text-xl text-[#666]">
                        <b className="align-center flex gap-2">
                          <FaUserEdit /> Customisation
                        </b>
                      </h1>
                      <hr className="my-3" />
                      <div className="pb-4">
                        <div>
                          <b>Choose theme</b>
                        </div>
                        <div className="">
                          <div className="themeHover ml-auto flex space-x-2">
                            <div
                              className={`h-8 w-8 cursor-pointer rounded-full bg-blue-500 ${
                                selectedColor === "#3B82F6" ? "active" : ""
                              }`}
                              onClick={() => handleColorChange("#3B82F6")}
                            ></div>
                            <div
                              className={`h-8 w-8 cursor-pointer rounded-full bg-red-500 ${
                                selectedColor === "#EF4444" ? "active" : ""
                              }`}
                              onClick={() => handleColorChange("#EF4444")}
                            ></div>
                            <div
                              className={`h-8 w-8 cursor-pointer rounded-full bg-green-500 ${
                                selectedColor === "#10B981" ? "active" : ""
                              }`}
                              onClick={() => handleColorChange("#10B981")}
                            ></div>
                            <div
                              className={`h-8 w-8 cursor-pointer rounded-full bg-purple-500 ${
                                selectedColor === "#8B5CF6" ? "active" : ""
                              }`}
                              onClick={() => handleColorChange("#8B5CF6")}
                            ></div>
                            <input
                              type="color"
                              className={`h-8 w-8 cursor-pointer ${
                                selectedColor === bgColor ? "active" : ""
                              }`}
                              value={bgColor}
                              onChange={(e) =>
                                handleColorChange(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <label className="text-black-800 block font-bold">
                        Select Avatar
                      </label>
                      <div className="mt-2 flex space-x-2">
                        {avatarOptions.map((avatar, index) => (
                          <img
                            key={index}
                            src={avatar}
                            alt={`Avatar ${index + 1}`}
                            className={`h-12 w-12 cursor-pointer rounded-full border-2 ${
                              selectedAvatar === avatar
                                ? "border-blue-500"
                                : "border-gray-300"
                            }`}
                            onClick={() => handleAvatarSelection(avatar)}
                          />
                        ))}
                      </div>
                      <div>
                        {avatarError && (
                          <div className="mt-2">
                            <p className="text-sm text-red-500">
                              {avatarError}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 rounded-md bg-white p-4 shadow">
                      <h1 className="text-xl text-[#666]">
                        <b className="align-center flex gap-2">
                          <IoIosGlobe /> Language
                        </b>
                      </h1>
                      <hr className="my-3" />
                      <div className="languageParent">
                        <div>
                          <label className="text-black-800 block cursor-pointer font-bold">
                            Choose Language
                          </label>
                          <div
                            className="langSelect align-center flex gap-2 align-middle"
                            onClick={openLanguageModal}
                          >
                            <label className="">Languages</label>
                            <FaGlobe />
                          </div>

                          {isModalOpen && (
                            <div className="modal-overlay">
                              <div className="modal">
                                <h1>
                                  <b>Select multiple languages</b>
                                </h1>
                                <br />
                                <button className="close" onClick={closeModal}>
                                  <IoMdClose />
                                </button>
                                <div className="languageList">
                                  <ul>
                                    {fetchedLanguages.map((language) => (
                                      <li
                                        key={language.code}
                                        className={
                                          activeLanguages?.some(
                                            (activeLang) =>
                                              activeLang.code === language.code
                                          )
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() =>
                                          handleLanguageClick(language)
                                        }
                                      >
                                        {language.name} ({language.code})
                                      </li>
                                    ))}
                                  </ul>
                                </div>

                                <div className="text-right">
                                  <button
                                    className="saveBtn rounded bg-blue-500 py-2 px-4 text-white"
                                    onClick={handlehandleUpdateBotSupportLang}
                                  >
                                    Save
                                  </button>
                                </div>

                                {/* Loader */}
                                {loading && (
                                  <div className="LangLoader">Saving...</div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <div>
                            <b>Default language</b>
                          </div>
                          <select
                            className="Deflanguage"
                            value={defaultLanguage}
                            onChange={(e) => setDefaultLanguage(e.target.value)}
                          >
                            <option value="" disabled>
                              Default Lang
                            </option>
                            {activeLanguages?.map((language) => (
                              <option key={language.code} value={language.code}>
                                {language.name} ({language.code})
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div style={{ marginTop: "", paddingLeft: "" }}>
                        {languageError && (
                          <div className="mt-2 ">
                            <p className="text-sm text-red-500">
                              {languageError}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 rounded-md bg-white p-4 shadow">
                      <h1 className="text-xl text-[#666]">
                        <b className="align-center flex gap-2">
                          <MdOutlinePayment /> Payment
                        </b>
                      </h1>
                      <hr className="my-3" />
                      <div>
                        <label className="text-black-800 block font-bold">
                          Payment Method
                        </label>
                        <div>
                          {paymentGateways.map((gateway) => (
                            <label
                              key={gateway.payment_gateway_id}
                              htmlFor={gateway.payment_gateway_id}
                              className="block"
                            >
                              {console.log("payment", selectedPaymentGateway, gateway.payment_gateway_id)}
                              <input
                                type="radio"
                                id={gateway.payment_gateway_id}
                                name="payment-method"
                                value={gateway.payment_gateway_id} // using payment_gateway_id as value
                                checked={
                                  selectedPaymentGateway ===
                                  gateway.payment_gateway_id
                                }
                                onChange={handlePaymentGatewayChange}
                                style={{ marginRight: "5px" }}
                              />
                              {gateway.name.charAt(0).toUpperCase() +
                                gateway.name.slice(1)}{" "}
                              {/* Display gateway name */}
                            </label>
                          ))}
                        </div>

                        {paymentMethodError && (
                          <div className="mt-2 ">
                            <p className="text-sm text-red-500">
                              {paymentMethodError}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 rounded-md bg-white p-4 shadow">
                      <h1 className="text-xl text-[#666]">
                        <b className="align-center flex gap-2">
                          <FaRobot /> Bot Knowledge
                        </b>
                      </h1>
                      <hr className="my-3" />
                      <KnowledgeUpdateForm botId={botId} />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className="sticky flex h-[26rem] flex-col items-center p-4"
                    style={{ top: "90px" }}
                  >
                    <div className="mb-2">Bot Agent Preview</div>
                    <div className="flex h-full w-80 flex-col rounded-lg bg-white shadow-md">
                      <div
                        className="rounded-t-xl p-4 text-center text-white"
                        style={{ backgroundColor: bgColor }}
                      >
                        {chatHeader}
                      </div>
                      <div
                        className="flex-1 p-2.5"
                        style={{ minHeight: "230px" }}
                      >
                        <div className="max-w-60 flex w-fit">
                          {selectedAvatar && (
                            <img
                              src={selectedAvatar}
                              alt="Selected Avatar"
                              className="inline-block h-8 w-8 rounded-full"
                            />
                          )}
                          <div className="ml-1 mr-1 rounded-3xl rounded-tl-none bg-[#f1f1f1] p-3 text-sm">
                            {welcomeMessage}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <input
                          type="text"
                          disabled
                          className="w-full rounded  p-2 text-sm"
                          placeholder="Type here and press enter..."
                        />
                      </div>
                    </div>
                    <div className="mt-4 flex w-full	flex-col items-center justify-center p-2">
                      {isBotCreated ? (
                        <button
                          onClick={handleUpdateBot}
                          className="rounded-md bg-blue-500 py-2 px-4 text-white"
                          style={{ width: "320px" }}
                        >
                          Update Bot
                        </button>
                      ) : (
                        <button
                          onClick={handleBotCreation}
                          className="rounded-md bg-blue-500 py-2 px-4 text-white"
                          style={{ width: "320px" }}
                        >
                          Create Bot
                        </button>
                      )}
                    </div>
                    <br />
                    {isBotCreated && (
                      <div className="mt-2 rounded border border-green-500 bg-green-100 p-4 text-green-700">
                        {/* <p className="text-sm">
                          Your bot has been created with ID: <b> {botId}</b>
                        </p> */}
                        <p className="text-sm font-bold">
                          Embed this script on your site:
                        </p>
                        <pre className="rounded bg-gray-100 p-2">
                          <code
                            className="whitespace-break-spaces text-xs"
                            style={{ whiteSpace: "normal" }}
                          >
                            {scriptTag}
                          </code>
                        </pre>
                        <button
                          onClick={handleCopyScript}
                          className="mt-2 rounded bg-green-500 py-2 px-4 text-sm text-white"
                        >
                          Copy Script
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {activeTab === "knowledge" && <KnowledgeUpdateForm botId={botId} />} */}
        </div>
      </div>
    </>
  );
};

export default Marketplace;
