import endpoints from "./endpoint";
import { sendRequest } from "./sendRequest";

export const _vendorRegister = async ({ body }) => {
    const url = endpoints.vendorRegister();
    try {
        const response = await sendRequest({url,method: 'POST', body});
        return response;
    } catch (error) {
        throw error;
    }
};

export const _createOrganization = async ({ body }) => {
    const url = endpoints.createOrganization();
    try {
        const response = await sendRequest({url,method: 'POST', body});
        return response;
    } catch (error) {
        throw error;
    }
};

export const _vendorLogin = async ({ body }) => {
    const url = endpoints.vendorLogin();
    try {
        const response = await sendRequest({url,method: 'POST', body});
        return response;
    } catch (error) {
        throw error;
    }
};

export const _vendorCategories = async () => {
    const url = endpoints.vendorCategories();
    try {
        const response = await sendRequest({url,method: 'GET'});
        return response;
    } catch (error) {
        throw error;
    }
};

export const _botCreation = async ({ body, accessToken }) => {
    const url = endpoints.botCreation();
    try {
        const response = await sendRequest({url,method: 'POST', body, headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }});
        return response;
    } catch (error) {
        throw error;
    }
};

export const _getLanguage = async ({accessToken}) => {
    const url = endpoints.getLanguage();
    try {
        const response = await sendRequest({url, method: 'GET', headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }});
        return response;
    } catch (error) {
        throw error;
    }
}



export const _languageUpdate = async ({ body, accessToken }) => {
    const url = endpoints.languageUpdate();
    try {
        const response = await sendRequest({url,method: 'PATCH', body, headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }});
        return response;
    } catch (error) {
        throw error;
    }
};

export const _userProfile = async (accessToken) => {
    const url = endpoints.userProfile();    
    console.log("accessToken: ", accessToken);
    try {
        const response = await sendRequest({url,method: 'GET', headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }});
        return response;
    } catch (error) {
        throw error;
    }
};
export const _organizationDetails = async (accessToken) => {
    const url = endpoints.getOrganizationDetails();    
    console.log("accessToken: ", accessToken);
    try {
        const response = await sendRequest({url,method: 'GET', headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }});
        return response;
    } catch (error) {
        throw error;
    }
};


export const _getPaymentGateways = async (token) => {
    const url = endpoints.getPaymentGateways();
    try {
        const response = await sendRequest({url,method: 'GET', headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }});
        return response;
    } catch (error) {
        throw error;
    }
};

export const _updateBotKnowledgeDetails = async ({ botId, file, url, accessToken }) => {
    const form = new FormData();

    if (file) {
        form.append('knowledge_source_file', file); 
    }
    
    if (url) {
        form.append('url', url);
    }

    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    const urlWithParams = `${endpoints.updateBotKnowledgeDetails()}?bot_id=${botId}`;

    try {
        const response = await sendRequest({
            url: urlWithParams,
            method: 'PATCH',
            body: form,
            headers,
        });
        return response;
    } catch (error) {
        throw error;
    }
};


export const _update_bot = async (botId, deatilsToBeUpdated, accessToken) => {
    try{
        const updateBotUrl = `${endpoints.updateBot(botId)}`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };
        const response = await sendRequest({
            url: updateBotUrl,
            method: 'PATCH',
            body: {...deatilsToBeUpdated},
            headers,
        });
        return response;

    } catch (error) {
        throw error;
    }
}

export const _getBotDetails = async ({accessToken}) => {
    const url = endpoints.getBot();
    try {
        const response = await sendRequest({url,method: 'GET', headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }});
        return response;
    } catch (error) {
        throw error;
    }
};

export const _getUserList = async ({ accessToken, page = 1, limit = 10 }) => {
    const url = endpoints.getUsersList(page, limit);
    try {
        const response = await sendRequest({
            url,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};


