import React, { useEffect, useState } from 'react';
import VendorTable from './VendorTable';
import { _getUserList } from '../../../Services/api'; // Adjust the import path as needed

function Vendor() {
    const [users, setUsers] = useState([]);
    const [pageInfo, setPageInfo] = useState({ page: 1, totalPages: 1, total: 0 });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const accessToken = localStorage.getItem('VendorToken'); 
                const response = await _getUserList({ accessToken, page: pageInfo.page, limit: 10 });
                if (response.status) {
                    setUsers(response.data.users);
                    setPageInfo({
                        page: response.data.page,
                        totalPages: response.data.total_pages,
                        total: response.data.total,
                    });
                } else {
                    console.error(response.message);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [pageInfo.page]);

    const handleNextPage = () => {
        if (pageInfo.page < pageInfo.totalPages) {
            setPageInfo((prev) => ({ ...prev, page: prev.page + 1 }));
        }
    };

    const handlePrevPage = () => {
        if (pageInfo.page > 1) {
            setPageInfo((prev) => ({ ...prev, page: prev.page - 1 }));
        }
    };

    const columns = ["First Name", "Last Name", "Email", "Phone Number", "Type"];

    return (
        <>
            <VendorTable
                columns={columns}
                data={users}
                loading={loading}
                pageInfo={pageInfo}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
            />
        </>
    );
}

export default Vendor;