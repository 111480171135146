import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";

const App = () => {
  const vendorToken = localStorage.getItem("VendorToken");

  return (
    <Routes>
      {vendorToken ? (
        <>
          <Route path="admin/*" element={<AdminLayout />} />
          <Route path="rtl/*" element={<RtlLayout />} />
          <Route path="/" element={<Navigate to="/admin/default" replace />} />
        </>
      ) : (
        <Route path="auth/*" element={<AuthLayout />} />
      )}
      <Route
        path="/"
        element={
          vendorToken ? (
            <Navigate to="/admin/default" replace />
          ) : (
            <Navigate to="/auth/sign-in" replace />
          )
        }
      />
      <Route
        path="*"
        element={
          vendorToken ? (
            <Navigate to="/admin/default" replace />
          ) : (
            <Navigate to="/auth/sign-in" replace />
          )
        }
      />
    </Routes>
  );
};

export default App;