const baseUrl = "https://chatbotdev.plistbooking.app/api";
// const baseUrl = "http://localhost:8000/api";

const endpoints = {
    vendorRegister: () => `${baseUrl}/register`,
    createOrganization: () => `${baseUrl}/organizations/`,
    vendorLogin: () => `${baseUrl}/login`,
    vendorCategories: () => `${baseUrl}/vendor_categories`,
    botCreation: () => `${baseUrl}/create_bot_initial`,
    languageUpdate: () => `${baseUrl}/update_bot_support_langs`,
    userProfile: () => `${baseUrl}/users/profile`,
    getOrganizationDetails: () => `${baseUrl}/user_organization`,
    getPaymentGateways: () => `${baseUrl}/payment-gateways`,
    updateBotKnowledgeDetails: () => `${baseUrl}/update_bot_knowledge_details`,
    getLanguage: () => `${baseUrl}/languages`,
    updateBot: (bot_id) => `${baseUrl}/bot/${bot_id}`,
    getBot: () => `${baseUrl}/user_bot`,
    getUsersList: (page, limit) => `${baseUrl}/users?page=${page}&limit=${limit}`,
};

export default endpoints;