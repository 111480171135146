import React, { useState } from "react";
import ChatSidebar from "./ChatSidebar";
import ChatWindow from "./ChatWindow";
import "./chat.scss";

const Help = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [conversations, setConversations] = useState({});

  const users = [
    { id: 1, name: "Alice" },
    { id: 2, name: "Bob" },
    { id: 3, name: "Charlie" },
  ];

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleAddMessage = (message) => {
    if (selectedUser) {
      setConversations((prevConversations) => {
        const userId = selectedUser.id;
        const newMessages = [
          ...(prevConversations[userId] || []),
          { text: message, sender: "You" },
        ];
        // Static response
        setTimeout(() => {
          setConversations((prevConversations) => ({
            ...prevConversations,
            [userId]: [
              ...newMessages,
              {
                text: "This is a static response from the bot.",
                sender: "Bot",
              },
            ],
          }));
        }, 1000); // Adjust the delay as needed
        return { ...prevConversations, [userId]: newMessages };
      });
    }
  };
  return (
    <div className="mt-3">
      <div className="shadow-lg" style={{ backgroundColor: "white" }}>
        <div className="chatIn flex">
          <ChatSidebar
            users={users}
            onUserSelect={handleUserSelect}
            selectedUser={selectedUser}
          />
          <ChatWindow
            user={selectedUser}
            messages={conversations[selectedUser?.id] || []}
            onAddMessage={handleAddMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default Help;
