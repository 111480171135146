import React from 'react';

const ChatSidebar = ({ users, onUserSelect, selectedUser }) => {
  return (
    <div className="w-1/4  border-r h-full">
      <div className="p-4 text-lg font-semibold">Users</div>
      <ul>
        {users.map((user) => (
          <li
            key={user.id}
            className={` text-sm p-3 px-4 cursor-pointer hover:bg-gray-100 ${selectedUser?.id === user.id ? 'bg-blue-500 text-white font-semibold text-sm hover:bg-blue-500' : ''}`}
            onClick={() => onUserSelect(user)}
          >
            
            {user.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatSidebar;
