import React, { useState } from "react";
import { _updateBotKnowledgeDetails } from "Services/api";

const KnowledgeUpdateForm = ({ botId }) => {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleSubmit = async () => {
    setError("");
    setSuccess("");
    // check whether the bot is created or not
    if(!botId) {
      setError("Please create a bot first.");
      return;
    }
    
    if (!file && !url) {
      setError("Please select a file and enter a URL both before submitting.");
      return;
    }
    console.log("file", file);
    console.log("url", url);
    
    try {
      console.log("botId", botId);
      setLoading(true);

      const accessToken = localStorage.getItem("VendorToken");

      const response = await _updateBotKnowledgeDetails({
        botId,
        file,
        url,
        accessToken,
      });
      if (!response.status) {
        setError("Error updating bot knowledge.");
        return;
      }
      console.log("here", response);
      setFile(null);
      setUrl("");

      setSuccess("Bot knowledge updated successfully!");
    } catch (err) {
      setError("Error updating bot knowledge.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="knowledge">
        <div className="upload-container">
          <input
            type="file"
            className="upload-input"
            accept=".pdf"
            onChange={handleFileChange}
          />
        </div>

        <input
          type="text"
          className="text-input"
          placeholder="Enter the URL"
          value={url}
          onChange={handleUrlChange}
        />
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>

      </div>
        {success && <p style={{ color: "green", textAlign:'left', fontSize:'14px', marginTop: '5px' }}>{success}</p>}
        {error && <p style={{ color: "red", textAlign:'left', fontSize:'14px', marginTop: '5px' }}>{error}</p>}
    </div>
  );
};

export default KnowledgeUpdateForm;
